import React                 from 'react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Subnavigation from '../components/Subnavigation';

const AltholzPage = (props) => {
  return (
    <>
      <HeaderMedia id="privat-1"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Altholzentsorgung',
          'path': '/altholzentsorgung'
        },
        {
          'displayName': 'Kaminholz',
          'path': '/kaminholz'
        },
        {
          'displayName': 'Streusalz',
          'path': '/streusalz'
        },
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle='Altholzentsorgung für Privat und Gewerbe'>Altholz</Heading>
        <Spacer/>
        <h3>Altholz Annahme</h3>
        <p>Die Annahme von Altholz : Verpackungsholz, Kisten, Paletten ist unser Beitrag zur umweltschonenden Entsorgung von Holzabfällen und ökologischer Energie - Erzeugung.</p>
        <p>Nach Inbetriebnahme unseres - mit eigenen Holzabfällen beschickten - Heizkraftwerkes entsorgen wir auch kostenpflichtig Ihr Altholz, wenn es die nachstehend aufgeführten Kriterien erfüllt:</p>
        <ul>
          <li>frei von Farbrückständen, Farbschichten und chemischer Kontaminierung</li>
          <li>Anlieferung mit eigenem PKW oder LKW (abkippen möglich)</li>
        </ul>
        <Spacer />
        <h3>Anlieferungszeiten</h3>
        <p>Montag bis Donnerstag von 8.00 - 14.00 Uhr, Freitag von 8.00 - 12.00 Uhr</p>
        <p>oder nach Terminabsprache</p>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default AltholzPage;